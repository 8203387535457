import React, { useState } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

function CorporateTraining() {
  const [formData, setFormData] = useState({
    fullName: "",
    organizationTime: "",
    mobileNumber: "",
    email: "",
    rightTime: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [submissionMessage, setSubmissionMessage] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let errors = {};
    if (!formData.fullName.trim()) {
      errors.fullName = "Full Name is required";
    }
    if (!formData.organizationTime.trim()) {
      errors.organizationTime = "Organization Time is required"; // Update error key
    }
    if (!/^\d{10}$/.test(formData.mobileNumber)) {
      errors.mobileNumber = "Mobile Number should be 10 digits";
    }
    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Invalid email format";
    }
    if (!formData.rightTime.trim()) {
      errors.rightTime = "Right Time to Contact is required"; // Update error key
    }
    if (!formData.message.trim()) {
      errors.message = "Message is required";
    }
    if (!captchaValue) {
      errors.captcha = "Please verify that you are not a robot";
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const requestBody = {
          ...formData,
          captchaToken: captchaValue,
        };
        console.log("Sending Request Body: ", requestBody);

        const response = await axios.post('http://node.skillhaara.com/user/corporate-training', requestBody);

        if (response.status === 201) {
          setSubmissionMessage("Form submitted successfully!");
          setFormData({
            fullName: "",
            organizationTime: "",
            mobileNumber: "",
            email: "",
            rightTime: "",
            message: "",
          });
          setCaptchaValue(null);
        }
      } catch (error) {
        setSubmissionMessage("Couldn't submit the Corporate Training form.");
        console.error("There was an error!", error);

        if (error.response) {
          console.error("Response Data:", error.response.data);
          setErrors({ server: error.response.data.error || "Server error occurred" });
        }
      }
    }
  };

  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <form className="responsive-form" onSubmit={handleSubmit}>
      <p className="description">"Transform Your Workforce – Discover Our Tailored Training Solutions!"</p>
      <div className="form-group">
        <input
          type="text"
          name="fullName"
          placeholder="Full Name"
          value={formData.fullName}
          onChange={handleChange}
          required
        />
        {errors.fullName && <p className="error">{errors.fullName}</p>}
        
        <input
          type="text"
          name="organizationTime" // Ensure this matches the state key
          placeholder="Organization"
          value={formData.organizationTime}
          onChange={handleChange}
          required
        />
        {errors.organizationTime && <p className="error">{errors.organizationTime}</p>}
      </div>
      <div className="form-group">
        <input
          type="text"
          name="mobileNumber"
          placeholder="Mobile Number"
          value={formData.mobileNumber}
          onChange={handleChange}
          required
        />
        {errors.mobileNumber && <small className="error" style={{ color: "white" }}>{errors.mobileNumber}</small>}
        
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        {errors.email && <p className="error">{errors.email}</p>}
      </div>
      <div className="form-group">
        <input
          type="text"
          name="rightTime" // Ensure this matches the state key
          placeholder="Right Time to Contact (in IST)"
          value={formData.rightTime}
          onChange={handleChange}
          required
        />
        {errors.rightTime && <p className="error">{errors.rightTime}</p>}
      </div>
      <div className="form-group">
        <textarea
          name="message"
          placeholder="Message"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>
        {errors.message && <p className="error">{errors.message}</p>}
      </div>
      
      {/* Add reCAPTCHA component */}
      <ReCAPTCHA
         sitekey="6LezOHEqAAAAAGocpY5W4qGBeaKwLAIYw9OfFc6m"
        onChange={onCaptchaChange}
      />
      {errors.captcha && <p className="error">{errors.captcha}</p>}
      
      <button type="submit" className="submit-btn">Submit</button>
      {submissionMessage && <strong className='sub-msg'>{submissionMessage}</strong>}
    </form>
  );
}

export default CorporateTraining;
