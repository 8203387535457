import React from 'react'
import contact from "../assets/images/contactban.jpg"
function Baner() {
  return (
    <div className='Contact-baner-container'>
       <img src={contact} alt=""  />
    </div>
  )
}

export default Baner
